import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import "./style.scss"

import ContactModal from "../../../components/contactModal"

import WhiteBg from "/static/cases/shouhang/白色底图.png"
import MainImg from "/static/cases/shouhang/2_光伏行业_首航新能源.png"
import SolutionIcon1 from "/static/cases/shouhang/02_光伏行业-icon1.png"
import SolutionIcon2 from "/static/cases/shouhang/02_光伏行业-icon2.png"
import SolutionIcon3 from "/static/cases/shouhang/02_光伏行业-icon3.png"

const Shouhang = () => {
  const modalRef = useRef()
  return (
    <Layout>
      <ContactModal ref={modalRef} />
      <Seo
        title="首航新能源智能装柜高效解决人工CAD排柜项目_装箱管理软件-蓝胖智汇"
        keywords="首航新能源；CAD装柜；装柜软件；出口"
        description="蓝胖智汇专注自研装箱管理软件、智能装箱码垛系统，为客户提供仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
      />
      <div className="relative">
        <StaticImage
          src="../case-banner.png"
          alt="首航新能源智能装柜高效解决人工CAD排柜项目_装箱管理软件-蓝胖智汇"
          className="w-full"
        />
        <div className="absolute top-28 left-24 text-white text-3xl">
          成功案例
        </div>
      </div>
      <div>
        <div className="shouhang-container ">
          <div id="lead">
            <img src={WhiteBg} alt="底图" />
            <div className="giant">
              <h1 className="inline-block">首航新能源</h1> <span>跨国企业</span>
              <span>中国地区</span>
              <span>光伏业</span>
            </div>
            <div className="services">
              提供的产品与服务：装满满、企业定制化服务、提供智能排柜解决方案
            </div>
          </div>
          <div id="project-background">
            <div className="left">
              <div className="title">项目背景</div>
              <div className="para">
                光伏行业头部企业首航新能源，是一家全球领先的光伏和储能解决方案提供商，致力于成为数字能源解决方案的领航者，为全球户用、工商业、大型地面电站提供创新的技术与系统解决方案。
              </div>
              <div className="para">
                公司核心产品涵盖1-255kW光伏逆变器、3-20kW储能逆变器、储能电池、数据中心能源系统以及数字能源系统。近年业务保持持续增长，并专注于加速布局海外市场。其核心产品涵盖光伏逆变器、储能逆变器、高低压电池、充电桩以及“光、储、充”系统解决方案。
              </div>
              <div className="para">
                蓝胖子为其提供智能排柜解决方案，高效解决其货物产品出口的排柜难题。
              </div>
            </div>
            <img src={MainImg} alt="光伏行业 首航新能源" />
          </div>
        </div>
        <div id="pain-point">
          <div className="content">
            <div className="title">客户痛点</div>
            <div className="points">
              <div className="point point1">
                人工需通过CAD进行装柜方案设计，同时需响应多地区工厂紧急排柜需求
              </div>
              <div className="point point2">
                销售、工程物流部反复沟通确认订单及排柜方案，沟通协作成本高
              </div>
              <div className="point point3">单个排柜方案生成CAD制图需1小时</div>
              <div className="point point4">
                人工查找及匹配机型数据库，耗时且易出错；新品增加或包装更新维护时，灵活响应差
              </div>
            </div>
          </div>
        </div>
        <div id="solutions">
          <div className="title">解决方案</div>
          <div className="about">
            提供针对逆变器类产品场景提供智能拼柜解决方案，以SaaS平台实现。截至目前，通过使用智能拼柜解决方案完成了超过14万立方米的货物，每日平均完成8个货柜的排柜规划任务。满足用户以下需求：
          </div>
          <div className="cards">
            <div className="card">
              <div className="card-content">
                <img src={SolutionIcon1} alt="icon" />
                <div className="text">
                  货物专属数据库，相关排柜数据直接调用完成规划
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <img src={SolutionIcon2} alt="icon" />
                <div className="text">
                  一键发送排柜方案至各部门，随时调整方案，加快各部门间完成订单优化及排柜指导工作
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <img src={SolutionIcon3} alt="icon" />
                <div className="text">
                  3D模拟图形式展现排柜方案，直观、清晰、快速
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="benefits">
          <div className="content">
            <div className="title">项目效益</div>
            <div className="cards">
              <div className="card">
                <div className="head">12倍</div>
                <div className="desc">排柜效率提升(1-24小时)</div>
              </div>
              <div className="card">
                <div className="head">8分钟</div>
                <div className="desc">平均方案规划时间</div>
              </div>
              <div className="card">
                <div className="head">100%</div>
                <div className="desc">替代人工使用CAD排柜规划</div>
              </div>
              <div className="card">
                <div className="head">1天内</div>
                <div className="desc">系统上手时间</div>
              </div>
              <div className="card">
                <div className="head">一键发送</div>
                <div className="desc">方案发送至各部门效率提升</div>
              </div>
            </div>
          </div>
        </div>
        <div id="misc">
          <div className="content">
            <div className="qa">
              <div className="title">其他问题</div>
              <div className="detail">
                告诉我们您需要解决什么问题，专家会帮助您找到最适合您的解决方案
              </div>
              <button
                onClick={() => modalRef.current.openModal()}
                className="contact-us-now"
                gtm-id="cases-contact-us"
              >
                联系我们
              </button>
            </div>
            <div className="try">
              <div className="product">
                <div className="about">开始体验 装满满-精简版</div>
                <div
                  className="go check-zmm-lite"
                  onClick={() =>
                    window.open("https://zmm-lite.doraopt.com?ref=h")
                  }
                >
                  立即体验
                </div>
              </div>
              <div className="product">
                <div className="about">更多产品</div>
                <div
                  className="go check-products"
                  onClick={() => navigate("/products")}
                >
                  查看更多产品
                </div>
              </div>
              <div className="product">
                <div className="about">继续浏览</div>
                <div
                  className="go check-cases"
                  onClick={() => navigate("/cases")}
                >
                  查看更多成功案例
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Shouhang
